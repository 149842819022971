import * as Sentry from '@sentry/sveltekit';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
	dsn: 'https://214fa7d27d264179bab391110777cb31@o1078727.ingest.sentry.io/4504725050228736',
	tracesSampleRate: 1,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
	integrations: [new Sentry.Replay({ maskAllText: false, blockAllMedia: false })]
});

export const handleError = Sentry.handleErrorWithSentry();
